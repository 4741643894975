import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {
    GameReviewsResponse,
    IdResponse,
    Page,
    PostCommentResponse,
    ReviewCountResponse,
    ReviewResponse,
    ReviewsResponse,
} from "../../model/responses";
import {environment} from "../../../environments/environment";
import {ReviewRequest,} from "../../model/requests";
import {ReviewSort} from "../../model/review-sort";
import {FeedType} from "../../model/feed-type";

@Injectable()
export class ReviewService {

    constructor(
        private http: HttpClient,
    ) {
    }

    getReview(reviewId: string): Observable<ReviewResponse> {
        return this.http.get<ReviewResponse>(`${environment.apiUrl}/reviews/${reviewId}`);
    }

    getUserReviews(userId: string, sort?: ReviewSort): Observable<ReviewsResponse> {
        let params = new HttpParams()
        if (sort) {
            params = params.set('sort', sort)
        }
        return this.http.get<ReviewsResponse>(`${environment.apiUrl}/reviews/user/${userId}`, {params: params});
    }

    getUserReviewCount(userId: string): Observable<ReviewCountResponse> {
        return this.http.get<ReviewCountResponse>(`${environment.apiUrl}/reviews/user/${userId}/count`);
    }

    getGameReviews(gameId: string): Observable<GameReviewsResponse> {
        return this.http.get<GameReviewsResponse>(`${environment.apiUrl}/reviews/game/${gameId}`);
    }

    getFeed(pageSize: number, pageIndex: number, type?: FeedType): Observable<Page<ReviewResponse>> {
        let params = new HttpParams()
            .set('pagination', pageSize + ',' + pageIndex)
        if (type) {
            params = params.set('type', type)
        }
        return this.http.get<Page<ReviewResponse>>(`${environment.apiUrl}/reviews/feed`, {params: params});
    }

    createReview(request: ReviewRequest): Observable<IdResponse> {
        return this.http.post<IdResponse>(`${environment.apiUrl}/reviews`, request);
    }

    updateReview(reviewId: string, request: ReviewRequest): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/reviews/${reviewId}`, request);
    }

    deleteReview(reviewId: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/reviews/${reviewId}`);
    }

    likeReview(reviewId: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/reviews/${reviewId}/likes`, {});
    }

    unlikeReview(reviewId: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/reviews/${reviewId}/likes`);
    }

    commentReview(reviewId: string, comment: string, parentId?: string): Observable<PostCommentResponse> {
        let params = new HttpParams()
        if (parentId) {
            params = params.set('parentId', parentId)
        }
        return this.http.post<PostCommentResponse>(`${environment.apiUrl}/reviews/${reviewId}/comments`, {content: comment}, {params: params});
    }

    deleteComment(reviewId: string, commentId: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/reviews/${reviewId}/comments/${commentId}`);
    }

    likeComment(reviewId: string, commentId: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/reviews/${reviewId}/comments/${commentId}/likes`, {});
    }

    unlikeComment(reviewId: string, commentId: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/reviews/${reviewId}/comments/${commentId}/likes`);
    }
}
